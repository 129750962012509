import React from 'react';

import megafon from '../../../assets/images/main/megafon.png'
import laptop from '../../../assets/images/main/Laptop.png'

import styles from './main.module.scss';

const SCROLL_HEIGHT = 140;

const Main = () => {
  const scroll = () => {
    const top = Math.floor(document.getElementById('request').getBoundingClientRect().top);
    const { offsetHeight } = document.body;
    const { pageYOffset, innerHeight } = window;
    if (top !== 0 && pageYOffset + innerHeight < offsetHeight) {
      const height = top - SCROLL_HEIGHT < 0 ? pageYOffset + top : pageYOffset + SCROLL_HEIGHT;
      window.scrollTo(0, height);
      requestAnimationFrame(scroll);
    }
  };

  return (
    <section className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <span className={styles.product}>Онлайн-факторинг для&nbsp;бизнеса</span>
          <span className={styles.description}>
            Платформа для компаний, работающих с&nbsp;отсрочкой платежа
          </span>
          <button className={styles.button} onClick={scroll} >Оставить заявку</button>
        </div>
        <div className={styles.imagesContainer}>
          <img src={megafon} alt={megafon} className={styles.imageMegafon} />
          <img src={laptop} alt={laptop} className={styles.imageLaptop} />
        </div>
      </div>
    </section>
  );
};

export { Main };
