import styled, { css } from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const themes = {
	grey: css`
    color: ${colors.silver_0};
    background-color: ${colors.silver_80};

    &:hover {
    	background-color: ${colors.silver_70};
    }
    
    &:active {
    	background-color: ${colors.silver_60};
    }
`,
	'dark-grey': css`
    color: ${colors.silver_0};
    background-color: ${colors.silver_70};

    &:hover {
    	background-color: ${colors.silver_60};
    }
    
    &:active {
    	background-color: ${colors.silver_50};
    }
`,
	blue: css`
		background-color: ${colors.blue_10};
    color: ${colors.silver_100};

    &:hover {
    	background-color: ${colors.blue_08};
    }
    
    &:active {
    	background-color: ${colors.blue_06};
    }
`,
	red: css`
		background-color: ${colors.red_10};
    color: ${colors.silver_100};

    &:hover {
    	background-color: ${colors.red_08};
    }
    
    &:active {
    	background-color: ${colors.red_06};
    }
`,
	green: css`
    background-color: ${colors.green_10};
    color: ${colors.silver_100};
    
    &:hover {
    	background-color: ${colors.green_08};
    }
    
    &:active {
    	background-color: ${colors.green_06};
    }
`,
	'outline-blue': css`
    background-color: transparent;
    border: 1px solid ${colors.blue_10};
    color: ${colors.blue_10};
`,
	'outline-red': css`
    background-color: transparent;
    border: 1px solid ${colors.red_10};
    color: ${colors.red_10};
`,
	'no-outline': css`
    background-color: transparent;
    color: ${colors.blue_10};
    
    &:hover {
      background-color: ${colors.blue_80};
    }`,
	mf_green: css`
		border-radius: 25px;
		color: ${colors.silver_100};
		background-color: ${colors.mf_green_10};
		
		&:hover {
			background-color: ${colors.mf_green_08};
		}
		
		&:active {
			background-color: ${colors.mf_green_06};
		}`,
	'mf_no-outline': css`
		border-radius: 25px;
		color: ${colors.green_10};
    background-color: transparent;
		
		&:hover {
			background-color: ${colors.mf_silver_90};
		}
		
		&:active {
			color: ${colors.green_04};
			background-color: ${colors.mf_silver_80};
		}`,
	mf_grey: css`
		border-radius: 25px;
    color: ${colors.mf_silver_0};
    background-color: ${colors.mf_silver_80};

    &:hover {
    	background-color: ${colors.mf_silver_70};
    }
    
    &:active {
    	background-color: ${colors.mf_silver_60};
    }
`,
	'mf_dark-grey': css`
		border-radius: 25px;
    color: ${colors.mf_silver_0};
    background-color: ${colors.mf_silver_70};

    &:hover {
    	background-color: ${colors.mf_silver_60};
    }
    
    &:active {
    	background-color: ${colors.mf_silver_50};
    }
`,
};

const sizes = {
	xs: css`
    font-size: 14px;
    padding: 3px 10px;`,
	s: css`
    font-size: 14px;
    padding: 7px 12px;`,
	m: css`
    font-size: 18px;
`,
	l: css`
    line-height: 1.5;
    font-size: 20px;
    padding: 9px 25px 11px 25px;`,
};

const fontWeights = {
	medium: css`
		font-weight: 500;`,
	bold: css`
		font-weight: 600;`,
	heavy: css`
		font-weight: 900;`,
};

const disabled = css`
    opacity: .3;
    cursor: default;
    
    &:focus {
    	outline: none;
	  }
	  
	  &:hover {
	    transition: none;
	  }
	
	  &:active {
	    transform: none !important;
	  }
`;

const loading = css`
    .text {
      padding-left: 20px;
    }
`;

const StyledButton = styled.button`
  text-decoration: none;
  border: none;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 25px 10px 25px;
  position: relative;
  ${normalizeFont(14)};
  white-space: nowrap;
  ${props => themes[props.theme]}
  ${props => sizes[props.size]}
  ${props => fontWeights[props.fontWeight]}
  ${props => props.disabled && disabled}
  ${props => props.loading && loading}
  transition: background-color .3s ease,
  						margin-top .3s ease;
  
  &:focus {
  	outline: none;
  }
  
  &:hover {
    transition: all 0s ease;
  }

  &:active {
    transform: translateY(1px);
  }
	
	.text {
	  position: relative;
	  display: flex;
	  justify-content: center;
	
	  img,
	  svg{
	    margin-right: 4px;
	  }
	
	  &--loading {
	    display: inline-block;
	  }
	}
	
	.img {
	  position: absolute;
	  top: 50%;
	  transform: translateY(-50%);
	
	  &--loading {
	    left: 0;
	  }
	
	  &--animate-loading {
	    display: inline-block;
	    width: 16px;
	    animation-name: loading;
	    animation-duration: 2s;
	    animation-iteration-count: infinite;
	    animation-timing-function: linear;
	  }
	}
	
		@keyframes loading {
	  from{ transform:rotate(0deg); }
	  to{ transform:rotate(360deg); }
	}
`;

export { StyledButton };
