import styled from 'styled-components';

import { normalizeFont, colors } from '../shared/styles';

const StyledProgressStatus = styled.div`
  display: inline-block;
  margin-left: 8px;

	.item {
	  display: flex;
	  align-items: baseline;
	  position: relative;
	
	  &--opacity {
	    opacity: .5;
	  }
	
	  &:not(:last-child):before {
	    content: "";
	    display: inline-block;
	    position: absolute;
	    left: 0;
	    top: 0;
	    bottom: 0;
	    width: 2px;
	    background: ${colors.silver_50};
	  }
	
	  &__icon {
	    position: absolute;
	    top: 0;
	    left: -7px;
	    z-index: 10;
	    background-color: ${colors.silver_100};
	  }
	
	  &__text {
	    margin-left: 20px;
	    margin-bottom: 20px;
	  }
	
	  &__text-waiting {
	    margin-left: 20px;
	    margin-bottom: 20px;
	    opacity: 0.5;
	  }
	
	  &__title {
	    ${normalizeFont(14)};
	    line-height: 1.29;
	    font-weight: 800;
	    color: ${colors.silver_0};
	  }
	
	  &__signed-by {
	    ${normalizeFont(14)};
	    line-height: 1.29;
	    color: ${colors.silver_0};
	  }
	
	  &__sign-date {
	    ${normalizeFont(14)};
	    line-height: 1.29;
	    color: ${colors.silver_10};
	  }
	}
`;

export { StyledProgressStatus };
