import styled from 'styled-components';

import { normalizeFont, colors } from '../shared/styles';

const getCheckboxDefaultHoverBorder = ({ invalid, disabled }) => {
	if (disabled) return `1px solid ${colors.silver_70}`;
	if (invalid) return `2px solid ${colors.mf_red_10}`;
	return `1px solid ${colors.silver_50}`;
};

const StyledCheckbox = styled.label`
  position: relative;
  cursor: pointer;

	.input {
	  position: absolute;
	  z-index: -1;
	  opacity: 0;
	}
	
	.text {
	  position: relative;
	  padding-left: 23px;
	  ${normalizeFont(14)};
	  color: ${props => (props.disabled ? colors.silver_70 : colors.silver_0)};
	
	  &:before {
	    content: '';
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    width: 14px;
	    height: 14px;
		border: ${props => (props.invalid ? `2px solid ${colors.mf_red_10}` : `1px solid ${colors.silver_70}`)};
		border-radius: 4px;
	    background-color: ${props => (props.disabled && colors.silver_70)};
	  }
	  
	  	  
	  &:hover:before {
	  	border: ${props => getCheckboxDefaultHoverBorder(props)};
	  }
	  
	  :focus {
			outline: none;
			&:before {
				border: ${props => (props.invalid ? `2px solid ${colors.mf_red_10}` : `1px solid ${colors.silver_70}`)};
				box-shadow: ${props => (props.invalid ? '0 0 0 3px rgba(246, 36, 52, 0.2)' : '0 0 0 3px rgba(0, 185, 86, 0.2)')};
			}
		}
	}
	
	& .input:checked + .text:before {
	  background-color: ${colors.mf_green_10};
	  border: solid 1px ${colors.mf_green_10};
	  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTMgNy41TDUuNSAxMGw1LjUxOC01LjUxOCIvPgo8L3N2Zz4K');
	}
	
	& .input:checked + .text:hover:before {
	  background-color: ${colors.mf_green_10};
	  border: solid 1px ${colors.mf_green_10};
	  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTMgNy41TDUuNSAxMGw1LjUxOC01LjUxOCIvPgo8L3N2Zz4K');
	}
	`;

export { StyledCheckbox };
