import React from 'react';

import { Head } from './Head';
import { Main } from './Main';

import styles from './top.module.scss';

const Top = () => {
  return (
    <section className={styles.section}>
      <Head />
      <Main />
    </section>
  );
};

export { Top };
