import React from 'react';
import { createRoot } from 'react-dom/client';

import './assets/style/index.scss';
import './assets/fonts/lato/latofonts.scss';
import './assets/fonts/MegaFonGraphikLC/megafongraphikls.scss'

import App from './app/App';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
