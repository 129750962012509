import styled from 'styled-components';

import { colors } from '../shared/styles';

const getRadioButtonDefaultHoverBorder = ({ invalid, disabled }) => {
	if (disabled) return `1px solid ${colors.silver_70}`;
	if (invalid) return `2px solid ${colors.mf_red_10}`;
	return `1px solid ${colors.silver_50}`;
};

const StyledRadioButton = styled.label`
	position: relative;
	
	.radio {
	  position: absolute;
	  z-index: -1;
	  opacity: 0;
	  margin: 10px 0 0 7px;
	}
	
	.text {
	  position: relative;
	  padding: 0 0 0 35px;
	  cursor: pointer;
	  color: ${props => (props.disabled ? colors.silver_60 : colors.silver_0)};
	  
	  :before {
			content: '';
			position: absolute;
			left: 0;
			top: 2px;
			width: 14px;
			height: 14px;
			border: ${props => (props.invalid ? `2px solid ${colors.mf_red_10}` : `1px solid ${colors.silver_70}`)};
			background-color: ${props => (props.disabled && colors.mf_silver_80)};
			border-radius: 50%;
			box-shadow: inset 0 1px 2px 0 rgba(86, 107, 120, 0.15);
			transition: .3s border, box-shadow;
		}
	  
	  &:hover:before {
	  	border: ${props => getRadioButtonDefaultHoverBorder(props)};
	  }
	  
	  :focus {
			outline: none;
			&:before {
				border: ${props => (props.invalid ? `2px solid ${colors.mf_red_10}` : `1px solid ${colors.silver_70}`)};
				box-shadow: ${props => (props.invalid ? '0 0 0 3px rgba(246, 36, 52, 0.2)' : '0 0 0 3px rgba(0, 185, 86, 0.2)')};
			}
		}
	}
		
	& .radio:checked + .text:before {
	  border: 1px solid ${colors.mf_green_10};
	  box-shadow: inset 0 0 0 4px ${colors.mf_green_10};
	}
	
	& .radio:checked + .text:hover:before {
	  border: 1px solid ${colors.mf_green_08};
	  box-shadow: inset 0 0 0 4px ${colors.mf_green_08};
	}
	
	& .radio:checked + .text:focus:before {
			border: solid 1px ${colors.mf_green_10};
			box-shadow: 0 0 0 3px rgba(0, 185, 86, 0.2),
						inset 0 0 0 4px ${colors.mf_green_08};
	}
`;

export { StyledRadioButton };
