import React from 'react';

import styles from './button.module.scss';

const Button = ({ children, onClick, className, disabled }) => {
  return (
    <button className={`${styles.button} ${className}`} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export { Button };
