import styled from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const TYPES = {
	DEFAULT: 'DEFAULT',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
};

const getColor = ({ type }) => {
	switch (type) {
		case TYPES.SUCCESS:
			return colors.green_10;
		case TYPES.ERROR:
			return colors.red_10;
		default:
			return colors.blue_10;
	}
};

const StyledNotification = styled.div`
  position: relative;
  pointer-events: auto;
  border-radius: 4px;
  padding: 5px 15px 10px 15px;
  margin-bottom: 20px;
  width: 285px;
  min-height: 76px;
  background-color: ${colors.silver_100};
  box-shadow: 0 0 4px 0 rgba(3, 41, 69, 0.2),
            	0 5px 10px 0 rgba(3, 49, 74, 0.14);
  border-left: 5px solid ${props => getColor(props)};

  .title {
    ${normalizeFont(11)};
    line-height: 1.82;
    letter-spacing: 1.9px;
    text-transform: uppercase;
    color:${props => getColor(props)};
  }


	.text {
	  vertical-align: middle;
	  ${normalizeFont(14)};
	
	  &:after {
	    content: '';
	    display: inline-block;
	    vertical-align: middle;
	    height: 100%;
	  }
	}
	
	.close {
	  z-index: 99;
	  cursor: pointer;
	  position: absolute;
	  text-align: center;
	  text-transform: uppercase;
	  padding: 15px;
	  right: 0;
	  top: 0;
	
	  &:after,
	  &:before {
	    position: absolute;
	    content: '';
	    display: block;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    height: 8px;
	    width: 1px;
	    background: ${colors.silver_10};
	    margin: auto;
	  }
	
	  &:after {
	    transform: rotate(45deg);
	  }
	
	  &:before {
	    transform: rotate(-45deg);
	  }
	}
`;

export { StyledNotification };
