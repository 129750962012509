import React from 'react';

import { Card } from './Card';

import styles from './scope.module.scss';

import workflow from '../../assets/images/scope/workflow.svg'
import financing from '../../assets/images/scope/financing.svg'
import refill from '../../assets/images/scope/refill.svg'
import better from '../../assets/images/scope/better.svg'

const SCOPE = [
  {
    image: workflow,
    text: 'Электронный документооборот между поставщиками, дебиторами и факторами'
  },
  {
    image: financing,
    text: 'Быстрое финансирование под уступку денежных требований'
  },
  {
    image: refill,
    text: 'Пополнение оборотных средств на выгодных условиях'
  },
  {
    image: better,
    text: 'Лучше кредита, потому что не требуется залог или поручительство'
  }
];

const Scope = () => {

  return (
    <section className={styles.scopeWrapper}>
      <div className={styles.scope}>
        <ul className={styles.list}>
          {SCOPE.map((scope, index) => (
            <Card {...scope} key={index} index={index} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export { Scope };
