import styled from 'styled-components';

import { normalizeFont, colors } from '../shared/styles';

const StyledTitle = styled.span`
  display: inline-block;
  ${normalizeFont(32)};
  font-weight: 600;
  line-height: 1.25;
  color: ${colors.silver_0};
`;

export { StyledTitle };
