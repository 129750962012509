import React from 'react';

import { MFLogo } from '../../../shared/ui-components';

import styles from './head.module.scss';

import logo from '../../../assets/images/logo.svg';
import login from '../../../assets/images/header/login.png'

const Head = () => {
  const appLink = window.location.host.startsWith('demo')
    ? `http://app-${window.location.host}`
    : `http://app.${window.location.host}`;

  return (
    <div className={styles.head}>
      <div className={styles.headWrapper} >
        <a href="https://megafon.ru/corporate" className={styles.logoContainer} >
          <MFLogo src={logo} alt="logo" className={styles.logo} />
        </a>
        <div className={styles.container}>
          <a className={styles.button} href={appLink} >
            Войти 
            <img className={styles.image} src={login} alt="enter" />
          </a>
        </div>
      </div>
    </div>
  );
};

export { Head };
