import { css } from 'styled-components';

const normalizeFont = fontSize => css`
    font-size: ${fontSize}px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal`;

const colors = {
	silver_0: '#131e24',
	silver_04: '#252e33',
	silver_06: '#2b353b',
	silver_10: '#6b7378',
	silver_30: '#97a1a7',
	silver_50: '#becad1',
	silver_60: '#cad4db',
	silver_70: '#d3dde3',
	silver_80: '#e3eaee',
	silver_90: '#edf2f5',
	silver_100: '#ffffff',

	blue_04: '#006099',
	blue_06: '#006fb0',
	blue_08: '#0076ba',
	blue_10: '#0080cc',
	blue_50: '#b2d8ef',
	blue_80: '#e1eff7',
	blue_90: '#f2faff',

	red_06: '#b50033',
	red_08: '#c20037',
	red_10: '#d4003b',
	red_80: '#ffe7e6',

	green_04: '#008a6c',
	green_06: '#009e7c',
	green_08: '#00a884',
	green_10: '#03b890',
	green_80: '#c9f2e0',

	orange_04: '#b37a00',
	orange_10: '#f7b21b',
	orange_80: '#ffefd1',
	orange_90: '#fff7e5',

	mf_silver_0: '#212621',
	mf_silver_04: '#232e23',
	mf_silver_06: '#293629',
	mf_silver_08: '#324032',
	mf_silver_10: '#6d736d',
	mf_silver_30: '#99a399',
	mf_silver_50: '#c2ccc2',
	mf_silver_60: '#ced6ce',
	mf_silver_70: '#d5ded5',
	mf_silver_80: '#e8edeb',
	mf_silver_90: '#f2f5f3',
	mf_silver_95: '#f7f9f7',

	mf_green_04: '#008a40',
	mf_green_06: '#00a14b',
	mf_green_08: '#00ab50',
	mf_green_10: '#00b956',
	mf_green_50: '#abdec3',
	mf_green_80: '#cbf2de',
	mf_green_90: '#edfcf4',

	mf_purple_10: '#731982',
	mf_purple_90: '#f5eff6',

	mf_orange_04: '#b87811',
	mf_orange_06: '#e3961b',
	mf_orange_08: '#eda024',
	mf_orange_10: '#ffa717',
	mf_orange_50: '#fde6c0',
	mf_orange_80: '#ffeed1',

	mf_red_06: '#b50b19',
	mf_red_08: '#c2101e',
	mf_red_10: '#f62434',
	mf_red_50: '#ffbfc5',
	mf_red_80: '#ffe6e7',
};

export { normalizeFont, colors };
