import styled, { css } from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const sizes = {
	s: css`
    font-size: 14px;
    padding: 5px 11px;
	`,
	m: css`
    font-size: 16px;
    padding: 9px 13px;
	`,
	l: css`
    font-size: 24px;
    padding: 8px 16px 10px 16px;
	`,
};

const StyledMFTextarea = styled.label`
	display: inline-block;
	position: relative;
	text-align: left;
	width: 100%;
	max-width: 620px;
	
	.textarea {
		${normalizeFont(14)};
		${props => sizes[props.size]};
		display: block;
		border: solid 1px ${colors.mf_silver_70};
		color: ${colors.mf_silver_0};
		background-color: transparent;
		width: 100%;
		height: 100%;
		resize: none;
		box-sizing: border-box;
		
		&:focus {
			outline: none;
			
			& + div.textarea-active-border {
				height: 2px;
				left: 0;
				right: 0;
			}
		}
		
		&:hover {
			border-color: ${colors.mf_silver_50};
		}
		
		&:disabled {
			color: ${colors.mf_silver_50};
			border-color: ${colors.mf_silver_80};
			
			&:hover {
				border-color: ${colors.mf_silver_80};
			}
		}
		
		&::placeholder {
			color: ${colors.mf_silver_30};
		}
	}
		
	.textarea-active-border {
		height: 0;
		background: ${props => (props.invalid ? colors.mf_red_10 : colors.mf_green_10)};
		position: absolute;
		bottom: 0;
		left: 20%;
		right: 20%;
		transition: all .3s;
	}
	
	.counter {
		position: absolute;
		bottom: -19px;
    	right: 5px;
		${normalizeFont(13)};
		color: ${colors.silver_10};

		&--warning {
			color: ${colors.mf_red_10};
		}
	}
`;

export { StyledMFTextarea };
