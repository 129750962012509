import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { StyledLoader } from './Loader.styles';

function LoaderIcon() {
	return (
		<svg className="img" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 16 16">
			<g fill="none" fillRule="evenodd">
				<circle cx="8" cy="8" r="7" stroke="#D2ECDE" strokeWidth="2" />
				<path fill="#01B956" d="M8 0a8 8 0 0 1 7.157 11.578l-1.79-.894A6 6 0 0 0 8 2l.001-2z" />
			</g>
		</svg>
	);
}

class Loader extends Component {
	static propTypes = { isFullSize: PropTypes.bool };

	static defaultProps = { isFullSize: false };

	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.state = { showLoader: false };
		this.timer = setTimeout(this.showLoader, 300);
	}

	componentDidMount() {
		document.body.appendChild(this.el);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
		document.body.removeChild(this.el);
	}

	showLoader = () => {
		this.setState({ showLoader: true });
	};

	render() {
		const { showLoader } = this.state;
		const { isFullSize } = this.props;

		if (!showLoader) { return null; }
		return createPortal(
			<StyledLoader isFullSize={isFullSize}>
				<LoaderIcon />
				<div className="overlay" />
			</StyledLoader>,
			this.el,
		);
	}
}

export { Loader };
