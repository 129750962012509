import React, { useState } from 'react';

import { Animate } from '../../shared/components/Animate';

import styles from './advantages.module.scss';

import safety from '../../assets/images/advantages/Safety.png';
import offer from '../../assets/images/advantages/Personal-offer.png';
import wifi from '../../assets/images/advantages/Wifi.png';
import social from '../../assets/images/advantages/Social.png';
import file from '../../assets/images/advantages/File.png';
import digital from '../../assets/images/advantages/Digital.png';

const ADVATAGES = [
  {
    image: safety,
    title: 'Сокращение кассовых разрывов',
    text: 'Факторинг помогает разморозить деньги, зависшие в дебиторской задолженности, и направить их в работу'
  },
  {
    image: offer,
    title: 'Доступ к&nbsp;лучшим \nпредложениям факторов',
    text: 'Выбирайте наиболее выгодные условия обслуживания и получайте финансирование по неоплаченным счетам'
  },
  {
    image: wifi,
    title: 'Все в онлайне',
    text: 'Все операции производятся удаленно, не нужно распечатывать документы и тратить время в офисе банка, документы подписываются электронной подписью'
  },
  {
    image: social,
    title: 'Платформа объединяет поставщиков, дебиторов и банки',
    text: 'Единая система для взаимодействия с участниками факторингового рынка'
  },
  {
    image: file,
    title: 'Юридически-значимый электронный документооборот между участниками',
    text: 'Документы, подписанные электронной подписью (ЭП) становятся юридически значимыми'
  },
  {
    image: digital,
    title: 'Автоматизация процессов',
    text: `В системе автоматизированы рутинные действия. Интеграция с учетной системой позволяет полностью автоматизировать процесс финансирования`
  }
];

const Advantages = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = () => {
    currentSlide === ADVATAGES.length - 1 ? setCurrentSlide(0) : setCurrentSlide(currentSlide + 1);

  }
  
  return (
    <section className={styles.advantages}>
      <span className={styles.title}>Преимущества</span>
        <ul className={styles.list}>
        {ADVATAGES.map(({ className, image, title, text }, index) => (
          <Animate key={index}>
            <li className={`${styles.listItem} ${className}`}>
              <div className={styles.imageContainer}>
                <img src={image} alt="img" />
              </div>
              <div className={styles.textBlock} >
                <div className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: title }} />
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </li>
          </Animate>
        ))}
      </ul>
      <div onClick={changeSlide} className={styles.slide} >
        <Animate>
            <div className={`${styles.listItem}`}>
              <div className={styles.imageContainer}>
                <img src={ADVATAGES[currentSlide].image} alt="img" />
              </div>
              <div className={styles.textBlock} >
                <div className={styles.itemTitle} dangerouslySetInnerHTML={{ __html: ADVATAGES[currentSlide].title }} />
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: ADVATAGES[currentSlide].text }} />
              </div>
            </div>
          </Animate>
          <div className={styles.pointsContainer} >{ADVATAGES.map((item, index) => (<div className={`${index === currentSlide ? styles.activePoint : styles.point}`} key={index} />))}</div>
      </div>
    </section>
  );
};

export { Advantages };
