import React from 'react';

import { Accordion } from './Accordion';

import facebook from '../../assets/images/footer/facebook.png';
import habr from '../../assets/images/footer/habr.png';
import twitter from '../../assets/images/footer/twitter.png';
import classmates from '../../assets/images/footer/classmates.png';
import youtube from '../../assets/images/footer/youtube.png';
import vk from '../../assets/images/footer/VK.png';
import instagram from '../../assets/images/footer/instagram.png';

import letter from '../../assets/images/footer/letter.png';
import restriction from '../../assets/images/footer/age-restrictions.png'

import styles from './footer.module.scss';

const INFO = {
  about: [
    {
      title: 'О компании',
      link: 'http://corp.megafon.ru/about/'
    },
    {
      title: 'Новости',
      link: 'http://corp.megafon.ru/press/news/federalnye_novosti/'
    },
    {
      title: 'Работа в Мегафоне',
      link: 'https://job.megafon.ru/'
    },
    {
      title: 'Условия оказания услуг',
      link: 'http://www.megafon.ru/corporate/help/term/'
    }
  ],
  companies: [
    {
      title: 'Инвесторам',
      link: 'http://corp.megafon.ru/investoram/'
    },
    {
      title: 'Партнерам',
      link: 'http://www.megafon.ru/partners/'
    },
    {
      title: 'Прессе',
      link: 'http://corp.megafon.ru/press/news/federalnye_novosti/'
    },
    {
      title: 'Лицензии',
      link: 'http://www.megafon.ru/ad/licenses'
    }
  ],
  interesting: [
    {
      title: 'Корпоративные дайджесты',
      link: 'https://digest.megafon.ru/'
    }
  ],
  socials: [
    {
      name: 'facebook',
      img: facebook,
      link: 'https://www.facebook.com/b2b.megafon.ru/'
    },
    {
      name: 'habr', 
      img: habr,
      link: 'https://habr.com/ru/company/megafon/'
    },
    {
      name: 'twitter', 
      img: twitter,
      link: 'https://twitter.com/megafonru'
    },
    {
      name: 'classmates',
      img: classmates,
      link: 'https://ok.ru/megafon'
    },
    {
      name: 'youtube',
      img: youtube,
      link: 'https://www.youtube.com/user/MegaFonTV'
    },
    {
      name: 'vk',
      img: vk,
      link: 'https://vk.com/megafon'
    },
    {
      name: 'instagram',
      img: instagram,
      link: 'https://www.instagram.com/megafon/'
    }
  ]
}

const getContentForDesctop = () => {
  return (
      <div className={styles.desktopContainer} >
        <div className={styles.aboutContainer}>
          <div className={styles.title} >Компания</div>
          <ul>
            {INFO.about.map((item, index) => (
              <li className={styles.itemContainer} key={index} >
                <a href={item.link} className={styles.listItem} >{item.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.companiesContainer}>
          <div className={styles.title} >Организациям</div>
          <ul>
            {INFO.companies.map((item, index) => (
              <li className={styles.itemContainer} key={index} >
                <a href={item.link} className={styles.listItem} >{item.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div className={styles.title} >Интересное</div>
          <ul>
            {INFO.interesting.map((item, index) => (
              <li className={styles.itemContainer} key={index} >
                <a href={item.link} className={styles.listItem} >{item.title}</a>
              </li>
            ))}
          </ul>
        </div>
        {getSocialBlock()}
      </div>
  );
}

const getContentForMobile = () => {
  return (
    <div className={styles.accordionContainer}>
      <Accordion title="Компания" list={INFO.about} />
      <Accordion title="Организациям" list={INFO.companies} />
      <Accordion title="Интересное" list={INFO.interesting} />
    </div>
  );
};

const getSocialBlock = () => {
  return (
        <div className={styles.contactsContainer}>
          <div className={styles.socialContainer} >
            <div className={styles.socialTitle} >
              МегаФон в соцсетях
            </div>
            <ul className={styles.social}>
              {INFO.socials.map((item, index) => (
                <li key={index} >
                  <a href={item.link}>
                    <img src={item.img} alt={item.name} className={styles.socialIcon} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.feedbackContainer} >
            <div>
              <img src={letter} className={styles.feedbackIcon} alt="feedback" />
            </div>
            <span className={styles.feedbackText} >
              Знаете, как сделать МегаФон Бизнес лучше? Напишите нам на <a href="mailto:Feedback.b2b@megafon.ru" className={styles.mail} >Feedback.b2b@megafon.ru</a>
            </span>
          </div>
        </div>
  );
}

const Footer = () => {

  return (
    <section className={styles.footer}>
      <div className={styles.containerTop}>
        {getContentForDesctop()} 
        {getContentForMobile()}
      </div>
      <div className={styles.containerBottom} >
        {getSocialBlock()}
        <div className={styles.agreementContainer}>
          <div className={styles.agreement} >
          Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookies и других пользовательских данных, в соответствии с 
            <a href={`${process.env.PUBLIC_URL}/privacy_policy.pdf`} className={styles.privacyPolicy} alt="privacyPolicy" target="_blank" rel="noopener noreferrer" > Политикой конфиденциальности</a>.
          </div>
          <div className={styles.rights} >
            <img src={restriction} className={styles.restriction} alt="restriction" />
            <span className={styles.copyright} >© 2020 ПАО «МегаФон»</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Footer };
