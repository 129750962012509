import styled from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const StyledIfValue = styled.span`
  ${normalizeFont(16)};
  color: ${colors.silver_30};
  line-height: 1.25;
`;

export { StyledIfValue };
