import React from 'react';
import PropTypes from 'prop-types';

import { StyledRow } from './Table.styles';

function Row({ children, className, ...props }) {
	return <StyledRow className={className} {...props}>{ children }</StyledRow>;
}

Row.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	isNew: PropTypes.bool,
};

Row.defaultProps = {
	className: '',
	isNew: false,
};

Row.displayName = 'Row';

export { Row };
