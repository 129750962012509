import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';

class PaginationButton extends Component {
	static propTypes = {
		isLoading: PropTypes.bool.isRequired,
		isLast: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		count: PropTypes.number,
		totalCount: PropTypes.number,
		currentPage: PropTypes.number,
	};

	static defaultProps = {
		isLast: true,
		count: 25,
		totalCount: 0,
		currentPage: 0,
	};

	onChange = () => {
		const { onChange, currentPage } = this.props;
		const event = { target: { value: currentPage + 1 } };
		onChange(event);
	};

	getCount() {
		const { count, totalCount, currentPage } = this.props;
		const currentCount = count * (currentPage + 1);
		const offset = totalCount - currentCount;
		return (count > offset) ? offset : count;
	}

	render() {
		const { isLast, isLoading } = this.props;

		return (
			!isLast
				? (
					<Button
						size="l"
						theme="grey"
						loadingText="Загрузка..."
						loading={isLoading}
						disabled={isLoading}
						onClick={this.onChange}
						style={{
							width: '100%',
							marginTop: '30px',
							height: '50px',
						}}
					>
						{'Показать еще '}
						{this.getCount()}
					</Button>
				)
				: null
		);
	}
}

export { PaginationButton };
