import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { StyledTable } from './Table.styles';

import { Header } from './Header';
import { Row } from './Row';
import { Cell } from './Cell';

const Body = ({ children, ...props }) => React.Children
	.toArray(children)
	.map((child, index) => (child ? React.cloneElement(child, { ...props, key: index }) : null));

Body.displayName = 'Body';

class Table extends Component {
	static Header = Header;

	static Row = Row;

	static Cell = Cell;

	static Body = Body;

	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.node.isRequired,
	};

	static defaultProps = { className: '' };

	render() {
		const { children, className, ...rest } = this.props;

		return (
			<StyledTable className={className} {...rest}>
				<>
					{
						React.Children.toArray(children)
							.filter(child => child.type.displayName === 'Header')
					}
				</>
				<div className="body">
					{
						React.Children.toArray(children)
							.filter(child => child.type.displayName === 'Body')
					}
				</div>
			</StyledTable>
		);
	}
}

export { Table };
