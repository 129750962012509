import React from 'react';
import PropTypes from 'prop-types';

import { colors } from '../shared/styles';

import { StyledButton } from './Button.styles';

function Spinner({ color }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<g fill="none" fillRule="evenodd">
				<circle cx="8" cy="8" r="5.5" stroke={color} />
				<circle cx="13" cy="8" r="2.5" fill={color} />
			</g>
		</svg>
	);
}

Spinner.propTypes = { color: PropTypes.string.isRequired };

const getSpinnerColor = theme => {
	switch (theme) {
		case 'outline-red': return colors.red_10;
		case 'outline-blue': return colors.blue_10;
		case ('grey' || 'dark-grey'): return colors.silver_0;
		case 'mf_no-outline': return colors.mf_green_10;
		default: return colors.silver_100;
	}
};

function Button(props) {
	const {
		children, theme, loading, loadingText, disabled, size, fontWeight, className, type, ...rest
	} = props;

	return (
		<StyledButton
			{...rest}
			type={type}
			className={className}
			theme={theme}
			size={size}
			fontWeight={fontWeight}
			loading={Boolean(loading)}
			disabled={disabled}
		>
			{
				loading
					? (
						<span className="text text--loading">
							<span className="img img--loading">
								<span className="img--animate-loading">
									<Spinner color={getSpinnerColor(theme)} />
								</span>
							</span>
							{ loadingText || children }
						</span>
					)
					: (
						<span className="text">
							{ children }
						</span>
					)
			}

		</StyledButton>
	);
}

Button.propTypes = {
	theme: PropTypes.oneOf([
		'grey',
		'blue',
		'green',
		'outline-blue',
		'no-outline',
		'outline-red',
		'red',
		'dark-grey',
		'mf_green',
		'mf_red',
		'mf_orange',
		'mf_no-outline',
		'mf_grey',
		'mf_dark-grey',
	]),
	size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
	fontWeight: PropTypes.oneOf(['bold', 'heavy', 'medium']),
	type: PropTypes.oneOf(['submit', 'reset', 'button']),
	loadingText: PropTypes.string,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
};

Button.defaultProps = {
	theme: 'mf_green',
	size: 'l',
	fontWeight: 'heavy',
	type: 'button',
	loadingText: '',
	loading: false,
	disabled: false,
	className: '',
	children: null,
};

export { Button };
