import styled from 'styled-components';

import { colors } from '../shared/styles';

const StyledFileIcon = styled.div`
  width: fit-content;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: ${props => (props.theme === 'blue' ? colors.blue_80 : colors.green_80)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  
  svg {
		display: block;
	  margin-bottom: 6px;
	}

	.remove__button {
	  position: absolute;
	  cursor: pointer;
	  right: -8px;
	  top: -8px;
	  width: 20px;
	  height: 20px;
	  background-color:  ${colors.red_10};
	  color: ${colors.silver_100};
	  border-radius: 50%;
	  display: flex;
		justify-content: center;
		align-items: center;
	
	  svg {
	    display: inline-block;
	    position: absolute;
	    transform: translate(-50%, -50%);
	    top: 50%;
	    left: 50%;
	    margin: 0;
	  }
	}
`;

export { StyledFileIcon };
