import styled from 'styled-components';

import { normalizeFont, colors } from '../shared/styles';

const getWidthCounter = ({ value, maxValue }) => {
	if (value > maxValue) return '30px';
	if (value >= 10) return '23px';
	return '18px';
};

const StyledCounter = styled.span`
  display: flex;
  background-color: ${colors.mf_purple_10};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: ${props => getWidthCounter(props)};
  height: 18px;
  max-width: 30px;

	.value {
		margin: 0 5px;
	  ${normalizeFont(11)};
	  color: ${colors.silver_100};
	  font-weight: 800;
	  line-height: 1.7;
	}
`;

export { StyledCounter };
