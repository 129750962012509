import styled from 'styled-components';

import { normalizeFont, colors } from '../shared/styles';

const StyledErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  
  .errorTitle {
  	${normalizeFont(32)};
  	font-weight: 800;
  	margin-top: 40px;
  	margin-bottom: 5px;
  }
  
  .errorCode {
  	${normalizeFont(16)};
  	color: ${colors.silver_10};
  	margin-bottom: 20px;
  }
  
  .errorText {
  	${normalizeFont(16)};
  	color: ${colors.silver_0};
  	margin-bottom: 30px;
  }
`;

export { StyledErrorPage };
