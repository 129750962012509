import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledMFDropdown, StyledMFDropdownOptionList, StyledMFDropdownOption } from './MFDropdown.styles';

function Arrow() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6" viewBox="0 0 12 6">
			<path fill="#99A399" fillRule="evenodd" d="M6 6l6-6H0z" />
		</svg>
	);
}

function Checked() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<path fill="#212621" fillRule="evenodd" d="M7 10.586L3.707 7.293 2.293 8.707 7 13.414l7.707-7.707-1.414-1.414z" />
		</svg>
	);
}

class MFDropdown extends Component {
	static propTypes = {
		defaultText: PropTypes.string,
		disabled: PropTypes.bool,
		options: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		})),
		invalid: PropTypes.bool,
		onSelect: PropTypes.func,
		onScrollEndCallback: PropTypes.func,
		value: PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	};

	static defaultProps = {
		defaultText: 'Выберите',
		disabled: false,
		options: [],
		invalid: false,
		onSelect: () => {},
		onScrollEndCallback: () => {},
		value: undefined,
	};

	static getInitialOptions(options, selected) {
		const value = selected && selected.value;
		return options.map(item => ({
			...item,
			isSelected: value === item.value,
			isHighlight: value === item.value,
		}));
	}

	constructor(props) {
		super(props);
		const { value, options } = this.props;
		const initialOptions = MFDropdown.getInitialOptions(options, value);
		const selected = initialOptions.find(item => (item.isSelected));
		const selectedIndex = initialOptions.indexOf(selected);

		this.state = {
			isOpen: false,
			options: initialOptions,
			selectedIndex,
			highlightIndex: selectedIndex,
		};
	}

	componentDidUpdate(prevProps) {
		const { options } = this.props;
		if (options !== prevProps.options) {
			this.onUpdate(this.props);
		}
	}

	onUpdate(data) {
		const { options, value } = data;
		this.setState({ options: MFDropdown.getInitialOptions(options, value) });
	}

	onSelect = selectedIndex => {
		const { onSelect, options } = this.props;
		const newOptions = options.map((item, index) => ({
			...item,
			isSelected: index === selectedIndex,
			isHighlight: index === selectedIndex,
		}));
		this.setState({
			options: newOptions,
			selectedIndex,
			isOpen: false,
		}, () => onSelect(options[selectedIndex]));
	};

	onOpen = () => {
		this.setState({ isOpen: true });
	};

	onClose = () => {
		this.setState({ isOpen: false });
	};

	onHighlight = focusedIndex => {
		const { options } = this.state;
		this.setState({
			options: options.map((item, index) => ({
				...item,
				isHighlight: index === focusedIndex,
			})),
			highlightIndex: focusedIndex,
		});
	};

	onKeyDown = event => {
		const arrowUp = 38;
		const arrowDown = 40;
		const enter = 13;
		const { highlightIndex, options } = this.state;
		const lastIndex = options.length - 1;
		const nextIndex = (highlightIndex + 1) > lastIndex ? 0 : highlightIndex + 1;
		const prevIndex = (highlightIndex - 1) < 0 ? lastIndex : highlightIndex - 1;

		if (event.keyCode === arrowUp) {
			this.onHighlight(prevIndex);
		}

		if (event.keyCode === arrowDown) {
			this.onHighlight(nextIndex);
		}

		if (event.keyCode === enter) {
			this.onSelect(highlightIndex);
			this.onClose();
		}
	};

	onScroll = event => {
		const element = event.target;
		const { onScrollEndCallback } = this.props;
		if (element.scrollHeight - element.scrollTop === element.clientHeight) {
			onScrollEndCallback();
		}
	};

	render() {
		const {
			defaultText, disabled, ...props
		} = this.props;
		const { isOpen, options, selectedIndex } = this.state;
		const selected = options[selectedIndex];
		const selectedLabel = selected?.label ? selected.label : defaultText;
		const isEmpty = !options.length;

		return (
			<StyledMFDropdown
				tabIndex="0"
				{...props}
				onClick={!disabled ? this.onOpen : null}
				disabled={disabled}
				onKeyDown={event => this.onKeyDown(event)}
				onBlur={!disabled ? this.onClose : null}
			>
				<div className="label">
					<span className="label__text--selected">{selectedLabel}</span>
				</div>
				<div className="active-border" />
				<span className="arrow"><Arrow /></span>
				<StyledMFDropdownOptionList
					isOpen={isOpen}
					className={`${isEmpty ? 'option__list--is-empty' : ''}`}
					onScroll={event => this.onScroll(event)}
				>
					{!isEmpty
						? options.map((item, index) => (
							<StyledMFDropdownOption
								tabIndex="0"
								className={`option ${item.isHighlight ? 'option--highlight' : ''}`}
								key={`${item.label}__${index}`}
								onClick={e => {
									e.stopPropagation();
									this.onSelect(index);
								}}
								onFocus={() => this.onHighlight(index)}
								onMouseEnter={() => this.onHighlight(index)}
							>
								<span className="label">{item.label}</span>
								{item.isSelected && <span className="checked"><Checked /></span>}
							</StyledMFDropdownOption>
						))
						: (
							<StyledMFDropdownOption className="option">
								<span className="text">Нет данных</span>
							</StyledMFDropdownOption>
						)}
				</StyledMFDropdownOptionList>
			</StyledMFDropdown>
		);
	}
}

export { MFDropdown };
