import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from '../Logo';
import { MFLogo } from '../MFLogo';
import { Button } from '../Button';

import { StyledErrorPage } from './ErrorPage.styles';

function MailToFactoring({ theme }) {
	return <a href="mailto:factoring-help@megafon.ru?subject=Ошибка на странице" style={{ all: 'unset', color: theme === 'mf' ? '#03b890' : '#0080cc', cursor: 'pointer' }}>factoring-help@megafon.ru</a>;
}

MailToFactoring.propTypes = {
	theme: PropTypes.oneOf(['default', 'mf']),
};

MailToFactoring.defaultProps = {
	theme: 'default',
};

const statusCodesMap = {
	400: {
		title: 'Ошибка на странице',
		text: theme => (
			<span>
				{'Попробуйте обновить страницу позже. Если ошибка повторяется - напишите '}
				<br />
				{'нам об этом на '}
				<MailToFactoring theme={theme} />
				{' или позвоните по номеру 8 800 222 81 00'}
			</span>
		),
	},
	404: {
		title: 'Страница не найдена',
		text: theme => (
			<span>
				Возможно, вы ошиблись, набирая адрес, или данная страница удалена.
				<br />
				{'Если адрес страницы верный - напишите нам об этом на '}
				<MailToFactoring theme={theme} />
				<br />
				или позвоните по номеру 8 800 222 81 00
			</span>
		),
	},
	403: {
		title: 'Доступ запрещён',
		text: theme => (
			<span>
				Убедитесь, что вы вошли в систему под профилем организации, которая
				<br />
				должна иметь доступ к этой странице
				<br />
				<br />
				{'Если вы уверены, что страница должна открываться - напишите нам об этом на '}
				<br />
				<MailToFactoring theme={theme} />
				{' или позвоните по номеру 8 800 222 81 00'}
			</span>
		),
	},
	500: {
		title: 'Сервис недоступен',
		text: theme => (
			<span>
				Произошла ошибка. Попробуйте воспользоваться сервисом позже.
				<br />
				{'Если ошибка повторяется - напишите нам об этом на '}
				<MailToFactoring theme={theme} />
				<br />
				или позвоните по номеру 8 800 222 81 00
			</span>
		),
	},
};

const validateStatusCode = code => ([400, 403, 404, 500].includes(code) ? code : 500);

const getLogo = theme => {
	switch (theme) {
		case 'default':
			return <Logo />;
		case 'mf':
			return <MFLogo />;
		default:
			return <Logo />;
	}
};

function ErrorPage({ statusCode, theme }) {
	const code = validateStatusCode(statusCode);

	return (
		<StyledErrorPage>
			<div>
				{ getLogo(theme) }
			</div>
			<span className="errorTitle">{statusCodesMap[code].title}</span>
			<span className="errorCode">
				{'Ошибка '}
				{code}
			</span>
			<div className="errorText">
				{statusCodesMap[code].text(theme)}
			</div>
			<a href={window.location.origin}>
				<Button
					style={{ width: '241px' }}
					theme={theme === 'mf' ? 'mf_green' : 'blue'}
					size="m"
					fontWeight="medium"
				>
					Перейти на главную
				</Button>
			</a>
		</StyledErrorPage>
	);
}

ErrorPage.propTypes = {
	statusCode: PropTypes.number,
	theme: PropTypes.oneOf(['default', 'mf']),
};

ErrorPage.defaultProps = {
	statusCode: 500,
	theme: 'default',
};

export { ErrorPage };
