import styled from 'styled-components';

import { colors } from '../shared/styles';

const StyledLoader = styled.div`
	.overlay {
	  position: fixed;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  z-index: 99998;
	  background: ${colors.silver_100};
	  opacity: 0.7;
	  margin: ${props => (props.isFullSize ? 'auto' : '70px auto auto')};
	}
	
	.img {
	  position: absolute;
	  margin: auto;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  z-index: 99999;
	  animation-name: loading;
	  animation-duration: 2s;
	  animation-iteration-count: infinite;
	  animation-timing-function: linear;
	  transform: translateY(-50%);
	}
	
	@keyframes loading {
	  from{ transform:rotate(0deg); }
	  to{ transform:rotate(360deg); }
	}
`;

export { StyledLoader };
