import styled, { css } from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const StyledFilePreview = styled.div`

  position: relative;
  display: inline-block;
  border: solid 1px ${colors.green_10};
  box-shadow: 0 5px 10px 0 rgba(0, 128, 204, 0.15);
  transition: .3s border-color, box-shadow;
  margin-bottom: 22px;
  ${props => !!props.href && css`
    &:hover {
      cursor: pointer;
      border: solid 1px ${colors.green_10};
    }`
}
	
	.preview {
	  width: 154px;
	  height: 218px;
	  background-color: white;
	  overflow: hidden;
	  margin-bottom: 6px;
	  opacity: 0.6;
	
	  &__img {
	    width: 100%;
	    height: 100%;
	    margin: auto;
	    position: relative;
	  }
	}
	
	.type {
	  position: absolute;
	  top: 10px;
	  left: -4px;
	  ${normalizeFont(10)};
	  font-weight: 900;
	  line-height: 1.8;
	  letter-spacing: 1px;
	  color: ${colors.silver_100};
	  background-color: ${colors.mf_silver_30};
	  padding: 1px 8px;
	  text-transform: uppercase;
	}
	
	.download {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  text-align: center;
	  transition: .3s opacity;
	
	  &__img {
	    width: 34px;
	    height: 33px;
	    margin-bottom: -5px;
	  }
	
	  &__text {
	    display: inline-block;
	    ${normalizeFont(16)};
	    line-height: 0.56;
	    font-weight: 600;
	    color: ${colors.green_10};
	  }
	}
`;

export { StyledFilePreview };
