import React, { useState } from 'react';

import close from '../../../assets/images/footer/close-icon.png'
import open from '../../../assets/images/footer/open-icon.png'
import arrow from '../../../assets/images/footer/arrow-right.png';

import styles from './accordion.module.scss';

const Accordion = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    const { title, list } = props;
    return (
        <div className={styles.accordion} >
            {list.length === 1 ? 
                <a className={`${styles.head}`} href={list[0].link} >
                    <div className={styles.title} >{list[0].title}</div>
                    <img src={arrow} className={styles.image} alt="status" />
                </a>
            :
            <div>
                <div onClick={handleOpen} className={`${styles.head} ${isOpen ? styles.open : styles.close}`} >
                    <div className={styles.title} >{title}</div>
                    <img src={isOpen ? open : close} className={styles.image} alt="status" />
                </div>
                {isOpen && <ul>
                    {list.map((item, index) => (
                        <li className={styles.item} key={index} >
                            <a href={item.link} className={styles.link} >{item.title}</a>
                        </li>
                    ))}
                </ul>}
            </div>
            }
        </div>
    );
}

export { Accordion }