import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
	StyledCell,
	StyledCellArrowSort,
} from './Table.styles';

function SortDescImg() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<path fill="#97A1A7" fillRule="evenodd" d="M8 11l4-5H4z" />
		</svg>
	);
}

function SortAscImg() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<path fill="#97A1A7" fillRule="evenodd" d="M8 5l4 5H4z" />
		</svg>
	);
}

class Cell extends Component {
	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.node,
		defaultSortDirection: PropTypes.oneOf(['', 'DESC', 'ASC']),
		onSort: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
		style: PropTypes.object,
	};

	static defaultProps = {
		className: '',
		children: null,
		onSort: null,
		defaultSortDirection: '',
		style: undefined,
	};

	static displayName = 'Cell';

	constructor(props) {
		super(props);
		const { defaultSortDirection } = this.props;
		this.state = {
			sortDirection: defaultSortDirection || '',
		};
	}

	onToggleSort = sortDirection => {
		const { onSort } = this.props;
		if (sortDirection === '') return this.setState({ sortDirection: 'DESC' }, () => onSort('DESC'));
		if (sortDirection === 'DESC') return this.setState({ sortDirection: 'ASC' }, () => onSort('ASC'));
		return this.setState({ sortDirection: '' }, () => onSort(''));
	};

	render() {
		const {
			children,
			className,
			onSort,
			style,
		} = this.props;
		const { sortDirection } = this.state;

		return (
			<StyledCell
				className={className}
				onClick={onSort ? () => this.onToggleSort(sortDirection) : null}
				isSortCell={!!onSort}
				style={style}
			>
				{ children }
				{sortDirection
				&& (
					<StyledCellArrowSort sortDirection={sortDirection}>
						{sortDirection === 'DESC'
							? <SortDescImg />
							: <SortAscImg />}
					</StyledCellArrowSort>
				)}
			</StyledCell>
		);
	}
}

export { Cell };
