import React, { useState } from 'react';

import { Slider } from './Slider';

import request from '../../assets/images/works/01-LAPTOP-REQUEST.png';
import requestx2 from '../../assets/images/works/01-LAPTOP-REQUEST@2x.png';

import contract from '../../assets/images/works/02-LAPTOP-CONTRACT.png';
import contractx2 from '../../assets/images/works/02-LAPTOP-CONTRACT@2x.png';

import notification from '../../assets/images/works/03-LAPTOP-NOTIFICATION.png';
import notificationx2 from '../../assets/images/works/03-LAPTOP-NOTIFICATION@2x.png';

import registy from '../../assets/images/works/04-LAPTOP-REGISTY.png';
import registyx2 from '../../assets/images/works/04-LAPTOP-REGISTY@2x.png';

import supply from '../../assets/images/works/05-LAPTOP-SUPPLY.png';
import supplyx2 from '../../assets/images/works/05-LAPTOP-SUPPLY@2x.png';

import styles from './works.module.scss';


const SLIDES = [
  {
    img: <img src={request} alt="macbook1" srcSet={`${requestx2} 2x`} width="100%" />,
    text: 'Получайте от факторов выгодные предложения с условиями финансирования вашего контракта'
  },
  {
    img: <img src={contract} alt="macbook1" srcSet={`${contractx2} 2x`} width="100%" />,
    text: 'Подпишите полученный от фактора договор факторинга с условиями обслуживания'
  },
  {
    img: <img src={notification} alt="macbook1" srcSet={`${notificationx2} 2x`} width="100%" />,
    text: 'Получите от фактора и подпишите уведомление дебитора с реквизитами факторингового счета'
  },
  {
    img: <img src={registy} alt="macbook1" srcSet={`${registyx2} 2x`} width="100%" />,
    text: 'Создайте реестр на финансирование из подтвержденных дебитором поставок и передайте фактору'
  },
  {
    img: <img src={supply} alt="macbook1" srcSet={`${supplyx2} 2x`} width="100%" />,
    text: 'Отслеживайте статус поставок в режиме онлайн'
  },
];

const Works = () => {
  const [selected, setSelected] = useState(0);

  const slideClick = () => {
    const next = selected + 1;
    if (next > 4) {
      setSelected(0);
    } else {
      setSelected(next);
    }
  };

  return (
    <section className={styles.works}>
      <div className={styles.title}>Как это работает</div>
      <div className={styles.container}>
        <Slider className={styles.slider} slides={SLIDES} selected={selected} setSelected={slideClick} />
        <div className={styles.selector}>
          <div className={styles.textContainer} >
            <span className={styles.text}>
              {SLIDES[selected].text}
            </span>
          </div>
          <ul className={styles.list}>
            {Array
              .from(new Array(SLIDES.length),(val, index)=> index + 1)
              .map((step, index) => (
                <div 
                  className={`${styles.listItemContainer} ${selected === index && styles.disableHover}`}
                  key={index}
                  onClick={() => setSelected(index)}>
                  <li
                    key={step}
                    className={`${styles.listItem} ${selected === index && styles.listItemSelected}`}>
                    <span className={styles.index}>{step}</span>
                  </li>
                  <span className={styles.text}>
                    {SLIDES[index].text}
                  </span>
                </div>
            ))}
            <div className={styles.listContainer}>
            {Array
              .from(new Array(SLIDES.length),(val, index)=> index + 1)
              .map((step, index) => (
                  <li
                    key={step}
                    className={`${styles.listItem} ${selected === index && (`${styles.listItemSelected} ${styles.disableHover}`)}`}
                    onClick={() => setSelected(index)}>
                      <span className={styles.index}>{step}</span>
                  </li>
            ))}
            </div>
          </ul>
        </div>
      </div>
    </section>
  );
};

export { Works };
