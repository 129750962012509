import React from 'react';

import styles from './documents.module.scss';

import docs from '../../assets/images/documents/docs.png'


const Documents = () => {

  return (
    <section className={styles.documents}>
      <span className={styles.title}>Документы</span>
      <div className={styles.container} >
        <img src={docs} alt={docs} className={styles.image} /> 
        <a href={`${process.env.PUBLIC_URL}/regulations.pdf`} className={styles.text} target="_blank" rel="noopener noreferrer" >
          <div>
            {`Регламент использования платформы Мегафон.Факторинг от 03.08.2020 `}
            <span>{`(PDF, 211 КБ)`}</span>
          </div> 
        </a>
      </div>
    </section>
  );
};

export { Documents };
