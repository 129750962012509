import styled, { css } from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const StyledTable = styled.div`
   position: relative;

	.body {
	  position: relative;
	  margin-bottom: 30px;
	}
`;

const StyledRow = styled.div`
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	min-height: 50px;
	padding: 14px 10px 16px;
	outline: none;
	transition: background-color .3s ease;
	${props => props.isNew && css`
    background-color: ${colors.mf_purple_90};
  `}
	
	&:hover {
		transition: all 0s ease;
	  background-color: ${colors.mf_silver_80};
	}
`;

const StyledCell = styled.div`
	position: relative;
	white-space: nowrap;
	margin-right: 30px;
	flex-basis: 100%;
	flex-shrink: 1;
	${normalizeFont(16)};
	cursor: ${props => (props.isSortCell ? 'pointer' : 'default')};
	
	&:last-child {
		margin-right: 0;
	}
`;

const StyledCellArrowSort = styled.span`
	position: absolute;
	right: 0;
	
	svg {
		display: inline-block;
		vertical-align: middle;
	}
`;

const StyledHeader = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: inset 0px -1px 0 0 ${colors.silver_50};
  color: ${colors.silver_10};
  text-align: left;
  padding: 13px 10px 5px;

  &:hover {
    background-color: ${colors.silver_100};
  }

  & div {
    ${normalizeFont(14)};
    line-height: 1.43;
  }
`;

export {
	StyledTable,
	StyledRow,
	StyledCell,
	StyledCellArrowSort,
	StyledHeader,
};
