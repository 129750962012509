import styled, { css } from 'styled-components';

import { normalizeFont, colors } from '../shared/styles';

const colorsMap = {
	success: {
		text: colors.green_04,
		circle: colors.green_10,
		background: colors.green_80,
	},
	warning: {
		text: colors.orange_04,
		circle: colors.orange_10,
		background: colors.orange_80,
	},
	error: {
		text: colors.red_10,
		circle: colors.red_10,
		background: colors.red_80,
	},
	default: {
		text: colors.silver_04,
		circle: colors.silver_10,
		background: colors.silver_80,
	},
};

const getStatusAdditionalStyles = ({ isDetailView }) => {
	if (isDetailView) {
		return css`
		  padding: 2px 8px;
		  height: 20px;
		  ${normalizeFont(11)};
		  border-radius: 10px;
		  color: ${props => colorsMap[props.displayType].text};
		  text-transform: uppercase;
		  letter-spacing: 1px;
		  font-weight: bold;
		  line-height: 1.60;
		  background: ${props => colorsMap[props.displayType].background};
`;
	} return css`
	  	padding-left: 14px;
	  	${normalizeFont(16)};
	
		  &::before {
		    content: '';
		    position: absolute;
		    display: block;
		    width: 9px;
		    height: 9px;
		    border-radius: 50%;
		    transform: translateY(-60%);
		    top: 50%;
		    left: 0;
		    background: ${props => colorsMap[props.displayType].circle};
		  }
`;
};

const StyledStatus = styled.span`
  display: inline-block;
  position: relative;
	${props => getStatusAdditionalStyles(props)}
`;

export { StyledStatus };
