import React from 'react';

import { RequestForm } from './RequestForm';

import styles from './request.module.scss';

const Request = () => {
  return (
    <section className={styles.request} id="request" >
      <RequestForm />
    </section>
  );
};

export { Request };
