import React from 'react';

import styles from './card.module.scss';

const Card = ({ title, text, index, className }) => {
  return (
    <li className={`${styles.card} ${className}`} style={{ animationDelay: 200 * index + 'ms' }}>
      <div className={styles.index}>{index}</div>
      <span className={styles.title}>{title}</span>
      <span className={styles.text}>{text}</span>
    </li>
  );
};

export { Card };
