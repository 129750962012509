import React from 'react';
import PropTypes from 'prop-types';

import { StyledHeader } from './Table.styles';

function Header({ children, className, ...props }) {
	return (
		<StyledHeader className={className}>
			{children.map((child, index) => (child
				? React.cloneElement(child, { ...props, key: index })
				: null))}
		</StyledHeader>
	);
}

Header.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

Header.defaultProps = { className: '' };

Header.displayName = 'Header';

export { Header };
