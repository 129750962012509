import React from 'react';
import PropTypes from 'prop-types';

import { StyledCounter } from './Counter.styles';

function Counter({
	value,
	className,
	calculateValue,
	maxValue,
	...props
}) {
	return (
		<StyledCounter className={`counter ${className}`} value={value} maxValue={maxValue} {...props}>
			<span className="value">
				{calculateValue ? calculateValue(value) : Counter.getCounter(value, maxValue)}
			</span>
		</StyledCounter>
	);
}

Counter.getCounter = (value, maxValue) => (value <= maxValue ? value : `${maxValue}+`);

Counter.propTypes = {
	value: PropTypes.number.isRequired,
	className: PropTypes.string,
	calculateValue: PropTypes.func,
	maxValue: PropTypes.number,
};

Counter.defaultProps = {
	className: '',
	calculateValue: null,
	maxValue: 99,
};

export { Counter };
