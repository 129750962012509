import styled, { css } from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const StyledMFDropdown = styled.div`
	${normalizeFont(16)};
	color: ${colors.mf_silver_0};
	display: inline-block;
 	box-sizing: border-box;
	position: relative;
	max-width: 220px;
	width: 220px;
	height: 40px;
	padding: 10px 30px 10px 13px;
	border: solid 1px ${props => (props.disabled ? colors.mf_silver_80 : colors.mf_silver_70)};
	outline: none;
	cursor: ${props => (props.disabled ? 'default' : 'pointer')};
	
	&:hover {
		border-color: ${props => (props.disabled ? colors.mf_silver_80 : colors.mf_silver_50)};
		
		& div.active-border {
			height: ${props => (props.disabled ? '0px' : '2px')};
			left: 0;
			right: 0;
		}
	}
	
	.label {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.label__text--selected {
		color: ${props => (props.disabled ? colors.mf_silver_50 : colors.mf_silver_0)};
		white-space: nowrap;
	}
	
	.active-border {
		height: 0;
		background: ${props => (props.invalid ? colors.mf_red_10 : colors.mf_green_10)};
		position: absolute;
		bottom: -1px;
		left: 20%;
		right: 20%;
		transition: all .3s;
	}
	
	.arrow {
		position: absolute;
		right: 10px;
		top: 8px;
	}
`;

const StyledMFDropdownOptionList = styled.div`
		position: absolute;
		max-height: 170px;
  	box-shadow: 0 40px 40px -10px rgba(0, 0, 0, 0.05),
  							0 5px 15px -2px rgba(0, 0, 0, 0.15);
  	background-color: #ffffff;
  	padding: 5px 0;
  	width: 100%;
  	left: 0;
  	top: 100%;
    overflow: hidden;
    overflow-y: auto;
    visibility: hidden;
    transform: none;
    opacity: 0;
    transition-property: transform, opacity, visibility;
    transition-duration: .25s;
    z-index: 10;
    
    ${props => (props.isOpen ? css`
    transform: translateY(4px);
    opacity: 1;
    visibility: visible;` : '')}
    
    &.option__list--is-empty {
    	
    	.option {
    		color: ${colors.mf_silver_50};
    	}
    }
    
		&::-webkit-scrollbar {
				background-color: ${colors.silver_100};
				width: 16px;
		}
		
		&::-webkit-scrollbar-track {
				background-color: ${colors.silver_100};
		}
		
		&::-webkit-scrollbar-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 4px solid ${colors.silver_100};
		}
		
		&::-webkit-scrollbar-button {display:none}
`;

const StyledMFDropdownOption = styled.div`
		padding: 10px 30px 10px 13px;
		position: relative;
		outline: none;
		background-color: ${colors.silver_100};
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	
		&:hover {
			background-color: ${colors.mf_silver_80};
		}
		
		&.option--highlight {
			background-color: ${colors.mf_silver_80};
		}
		
		.checked {
			position: absolute;
			right: 15px;
		}
`;

export { StyledMFDropdown, StyledMFDropdownOptionList, StyledMFDropdownOption };
