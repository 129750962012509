import React, { useState, useEffect } from 'react';
import axios from 'axios';

import InputMask from 'react-input-mask';

import { Input } from '../../../shared/components/Input';
import { Button } from '../../../shared/components/Button';

import iconSuccess from '../../../assets/images/request/iconSuccess.png';

import iconError from '../../../assets/images/request/iconError.png';

import styles from './request-form.module.scss';

const validateEmail = email => {
  const regExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  return regExp.test(email);
};

const validateUserName = userName => {
  return !/[0-9]/g.test(userName) && userName.length >= 2;
};

function RequestForm() {
  const [requestComplete, setRequestComplete] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [isDataNotFull, setIsDataNotFull] = useState(false);
  const [triedSubmitForm, setTriedSubmitForm] = useState(false);
  const [formData, setFormData] = useState({
    company: '',
    userName: '',
    email: '',
    phoneNumber: '',
    comment: ''
  });

  useEffect(() => {
    triedSubmitForm && validateForm(formData) ? setIsDataNotFull(false) : setIsDataNotFull(true);
  }, [formData, triedSubmitForm]);

  const validateForm = formData => {
    return (
      validateUserName(formData.userName) &&
      !formData.phoneNumber.includes('_') &&
      formData.company.length &&
      validateEmail(formData.email)
    );
  };

  const handleChange = event => {
    const { target } = event;

    setFormData({
      ...formData,
      [target.name]: target.value
    });
  };

  const handleResponse = isSuccess => {
    setRequestComplete(true);
    setRequestSuccess(isSuccess);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setTriedSubmitForm(true);
    validateForm(formData) ? setIsDataNotFull(false) : setIsDataNotFull(true);
    if (validateForm(formData)) {
      axios
        .post('/api/requisitions', formData)
        .then(() => handleResponse(true))
        .catch(() => handleResponse(false));
    } else {
      setIsDataNotFull(true);
    }
  };

  const handleGoBack = event => {
    event.preventDefault();
    setRequestComplete(false);
  };

  const getRequestResultBlock = requestSuccess => {
    if (requestSuccess) {
      return (
        <div className={styles.requestResult}>
          <img src={iconSuccess} alt="success" />
          <h1 className={styles.title}>Заявка отправлена</h1>
          <span className={styles.requestResultText}>
            В ближайшее время с вами свяжется менеджер для уточнения деталей.
          </span>
        </div>
      );
    }
    return (
      <div className={styles.requestResult}>
        <img src={iconError} alt="success" />
        <h1 className={styles.title}>Что-то пошло не так</h1>
        <span className={styles.requestResultText}>
          К сожалению произошла ошибка. Если ошибка повторится, пожалуйста, напишите нам на почту
          <a
            href="mailto:factoring-help@megafon.ru?subject=Запрос в поддержку МегаФон Факторинг"
            className={styles.link}
          >
            {' '}
            factoring-help@megafon.ru
          </a>
        </span>
        <Button className={`${styles.button} ${styles.buttonBack}`} onClick={handleGoBack}>
          Попробовать еще раз
        </Button>
      </div>
    );
  };

  const { phoneNumber } = formData;

  return (
    <div className={styles.requestForm}>
      {requestComplete ? (
        getRequestResultBlock(requestSuccess)
      ) : (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={`${styles.inputContainer}`}>
            <Input
              label="Как вас зовут"
              name="userName"
              placeholder="Введите ФИО"
              value={formData.userName}
              onChange={handleChange}
              notFilled={triedSubmitForm && isDataNotFull && !validateUserName(formData.userName)}
              className={`${styles.inputShort}`}
              warningMessage={'Укажите корректное имя'}
              autoComplete="off"
            />
            <Input
              label="Номер телефона для связи"
              notFilled={
                triedSubmitForm && isDataNotFull && (!phoneNumber || phoneNumber.includes('_'))
              }
              className={`${styles.inputShort}`}
              warningMessage={'Введите корректный номер телефона'}
            >
              <InputMask
                mask="+7 (999) 999-99-99"
                placeholder="+7 (___) ___-__-__"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className={`${styles.phoneInput} ${triedSubmitForm &&
                  isDataNotFull &&
                  (!phoneNumber || phoneNumber.includes('_')) &&
                  styles.warningInput}`}
              />
            </Input>
          </div>
          <Input
            label="E-mail для связи"
            type="email"
            name="email"
            placeholder="Введите адрес электронной почты"
            value={formData.email}
            onChange={handleChange}
            notFilled={triedSubmitForm && isDataNotFull && !validateEmail(formData.email)}
            className={`${styles.margin} ${styles.input}`}
            warningMessage={'Введите корректный e-mail'}
            autoComplete="off"
          />
          <Input
            label="Название организации и ИНН"
            name="company"
            placeholder="Введите наименование и номер"
            value={formData.company}
            onChange={handleChange}
            notFilled={
              triedSubmitForm &&
              isDataNotFull &&
              !formData.company.length
            }
            className={`${styles.margin} ${styles.input}`}
            warningMessage={'Неверные данные. Выберите организацию из списка'}
            autoComplete="off"
          />
          <Input label="Комментарий" name="comment" className={styles.margin}>
            <textarea
              name="comment"
              placeholder="Введите текст"
              value={formData.comment}
              onChange={handleChange}
              rows="5"
              cols="60"
              className={`${styles.input} ${styles.textarea}`}
            />
          </Input>

          <Button
            className={`${styles.button} ${triedSubmitForm &&
              isDataNotFull &&
              styles.disabledButton}`}
            disabled={triedSubmitForm && isDataNotFull}
          >
            <input type="submit" value="Отправить" />
          </Button>
          <span className={styles.agreement}>
            {' '}
            Нажимая «Отправить», я подтверждаю, что ознакомлен и принимаю <br />
            условия{' '}
            <a
              className={styles.link}
              href={`${process.env.PUBLIC_URL}/privacy_policy.pdf`}
              rel="noopener noreferrer"
              target="_blank"
            >
              политики конфиденциальности ПАО «МегаФон»
            </a>
          </span>
        </form>
      )}
    </div>
  );
}

export { RequestForm };
