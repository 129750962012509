import React from 'react';

import { Card } from './Card';

import styles from './connection.module.scss';

const STEPS = [
  {
    title: 'Оставьте заявку',
    text: 'Менеджер свяжется с вами для уточнения деталей'
  },
  {
    title: 'Получите приглашение на электронную почту',
    text: 'Менеджер направит приглашение на электронную почту'
  },
  {
    title: 'Начните работу',
    text: 'Отслеживайте свои поставки в режиме онлайн и \nполучайте финансирование на лучших условиях'
  }
];

const Connection = () => {
  return (
    <section className={styles.connection}>
      <span className={styles.title}>Подключить сервис</span>
      <ul className={styles.list}>
        {STEPS.map((step, index) => (
          <Card {...step} index={index + 1} key={index} />
        ))}
      </ul>
    </section>
  );
};

export { Connection };
