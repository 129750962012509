import React from 'react';

import styles from './card.module.scss';

const Card = ({ image, text, index }) => {
  return (
    <li className={styles.card} style={{ order: index }}>
      <div className={styles.image}>
        <img src={image} alt="img" />
      </div>
      <div className={styles.textWrapper}>
        <span className={styles.text}>{text}</span>
      </div>
    </li>
  );
};

export { Card };
