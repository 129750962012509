import styled from 'styled-components';

import { colors } from '../shared/styles';

const StyledModal = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 680px;
  min-width: 520px;
  z-index: 9996;
  animation-name: ${props => (props.isModalClose ? 'slideUp' : 'slideDown')};
  animation-duration: .3s;
  animation-timing-function: ease;
  visibility: visible;

	@keyframes slideDown {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
    }
	}
	
	@keyframes slideUp {
    0% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-100%);
    }
	}
	
  & .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9990;
    animation: loaded .3s;
  }
  
  & .modal__content {
    background-color: ${colors.silver_100};
    padding: 20px 0;
    border-radius: 4px;
    width: 100%;
  }
  
  & .modal__shadow {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 20px;
    max-width: 100%;
    margin: 0 40px;
    left: 0;
    right: 0;
    box-shadow: 0 5px 100px 0 ${colors.silver_0};
    z-index: -1;
  }
  
  & .modal__close-btn {
    position: absolute;
    right: 0;
    padding: 0;
    background: none;
    border: none;
    top: 0;
    cursor: pointer;
  }
  
  .open {
    filter: blur(2px);
    overflow: hidden;
  }
`;

const StyledModalOverlay = styled.div`
		position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9990;
    animation: loaded .3s;
    
    @keyframes loaded {
    	0%   { opacity: 0; }
    	100% { opacity: 1; }
		}
`;

export { StyledModal, StyledModalOverlay };
