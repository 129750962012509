import styled from 'styled-components';

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export { StyledLoader };
