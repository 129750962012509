import React, { createRef, Component } from 'react';

import { Animate } from '../../../shared/components/Animate';

import styles from './slider.module.scss';

class Slider extends Component {
  listRef = createRef();

  render() {
    const { slides, className, selected, setSelected } = this.props;

    return (
      <Animate>
        <div className={`${styles.container} ${className}`}>
          <ul className={styles.slider} >
            {slides.map((slide, index) => {
              return (
                <li
                  ref={this.listRef}
                  key={index}
                  className={`${styles.slide} ${index === selected ? styles.animateIn : styles.animateOut}`}
                  onClick={setSelected}>
                  {slide.img}
                </li>
              );
            })}
          </ul>
        </div>
      </Animate>
    );
  }
}

export { Slider };
