import React, { createRef, useState } from 'react';

import styles from './animate.module.scss';

const Animate = ({ children }) => {
  const [show, setShow] = useState(false);
  const ref = createRef();
  window.addEventListener('scroll', _ => {
    if (!show && ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      if (window.innerHeight - top >= 100) setShow(true);
    }
  });
  const newChildren = React.Children.map(children, child =>
    React.cloneElement(child, {
      className: `${child.props.className} ${show ? styles.animateIn : ''} ${styles.wrapper}`,
      ref
    })
  );

  return newChildren;
};

export { Animate };
