import React, { Component, Fragment } from 'react';

import { Top } from './Top';
import { Scope } from './Scope';
import { Advantages } from './Advantages';
import { Works } from './Works';
import { Documents } from './Documents';
import { Connection } from './Connection';
import { Request } from './Request';
import { Footer } from './Footer';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Top />
        <Scope />
        <Advantages />
        <Works />
        <Connection />
        <Request />
        <Documents />
        <Footer />
      </Fragment>
    );
  }
}

export default App;
