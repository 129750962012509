import styled from 'styled-components';

import { colors, normalizeFont } from '../shared/styles';

const StyledTableCount = styled.span`
	display: inline-block;
	${normalizeFont(23)};
	color: ${colors.silver_30};
`;

export { StyledTableCount };
