import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { StyledModal, StyledModalOverlay } from './Modal.styles';

const MODAL_OPEN = 'modal-open';

function CloseIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
			<g fill="none" fillRule="evenodd">
				<path d="M12 12h16v16H12z" />
				<path fill="#97A1A7" fillRule="nonzero" d="M20 18.94l4.47-4.47a.75.75 0 0 1 1.06 1.06L21.06 20l4.47 4.47a.75.75 0 0 1-1.06 1.06L20 21.06l-4.47 4.47a.75.75 0 0 1-1.06-1.06L18.94 20l-4.47-4.47a.75.75 0 0 1 1.06-1.06L20 18.94z" />
			</g>
		</svg>
	);
}

export class Modal extends Component {
	static propTypes = {
		onClose: PropTypes.func,
		children: PropTypes.node,
		withOverlayClose: PropTypes.bool,
		withShadow: PropTypes.bool,
		withOverlay: PropTypes.bool,
		overlayProps: PropTypes.shape({
			className: '',
		}),
	};

	static defaultProps = {
		onClose: () => {},
		children: null,
		withOverlayClose: false,
		withShadow: true,
		withOverlay: true,
		overlayProps: {},
	};

	constructor(props) {
		super(props);
		this.body = document.body;
		this.body.classList.toggle(MODAL_OPEN);
		this.bodyOverflow = this.body.style.overflow;
		this.body.style.overflow = 'hidden';

		this.state = {
			isModalClose: false,
		};
	}

	componentWillUnmount() {
		this.body.classList.toggle(MODAL_OPEN);
		this.body.style.overflow = this.bodyOverflow;
	}

	onClose = () => {
		const { onClose } = this.props;
		this.setState({ isModalClose: true }, () => setTimeout(onClose, 250));
	};

	render() {
		const {
			children,
			withShadow,
			withOverlay,
			withOverlayClose,
			overlayProps,
			...rest
		} = this.props;
		const { isModalClose } = this.state;

		return ReactDOM.createPortal(
			<>
				<StyledModal {...rest} isModalClose={isModalClose}>
					<div className="modal__content">
						<button type="button" className="modal__close-btn" onClick={this.onClose}>
							<CloseIcon />
						</button>
						{ React.Children
							.toArray(children)
							.map((child, index) => React.cloneElement(child, { key: `modal__${index}`, ...rest, onClose: this.onClose })) }
					</div>
					{withShadow && <div className="modal__shadow" />}
				</StyledModal>
				{withOverlay
				&& (
					<StyledModalOverlay
						role={withOverlayClose ? 'button' : 'presentation'}
						onClick={withOverlayClose ? this.onClose : null}
						className="modal__overlay"
						{...overlayProps}
					/>
				)}
			</>,
			this.body,
		);
	}
}

export default Modal;
