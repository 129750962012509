import React from 'react';

import styles from './input.module.scss';

const Input = (props) => {
  const { label, placeholder, className, onChange, type, value, name, notFilled, warningMessage, autoComplete } = props;
  return (
      <label className={`${styles.label} ${className} ${notFilled && styles.cross}`}>
        <div className={styles.labelText} >
          {label}
        </div>
          {props.children ? 
            props.children :
            <input
              className={`${styles.input} ${notFilled && styles.notFilled}`}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              name={name}
              type={type}
              autoComplete={autoComplete}/>
          }
        {notFilled && <div className={styles.warning} >
          {warningMessage}
        </div>}
      </label>
  );
};

export { Input };
